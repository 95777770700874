<template>
  <mcb-detail class="control" @esc="cancelHandle" @failure="failureHandle" failureText="Отказ" @success="successHandle" :readOnly="readOnly">
    <template v-slot:photo>
      <div class="photo">
        <img :src="'data:image/png;base64, ' + item" v-for="(item, key) in photo" :key="key" />
      </div>
    </template>

    <template v-slot:default>
      <el-form label-width="110px" label-position="left">
        <el-form-item label="Клиника">
          <div class="clinic">{{clinic}}</div>
        </el-form-item>
        <el-form-item label="Дата">
          <div class="date">{{ruDate(date)}}</div>
        </el-form-item>
        <el-form-item label="Специальность">
          <div class="speciality">{{speciality}}</div>
        </el-form-item>
        
        <el-form-item label="Диагноз">
          <mcb-multiple-values v-model="diagnoses">
            <template slot-scope="scope">
              <div class="diagnose">{{scope.item}}</div>
            </template>
          </mcb-multiple-values>
        </el-form-item>
        <el-form-item label="Препараты">
          <mcb-multiple-values v-model="drugs">
            <template slot-scope="scope">
              <div class="drug">{{scope.item}}</div>
            </template>
          </mcb-multiple-values>
        </el-form-item>
        <el-form-item label="Причина" prop="reason" v-if="reason && readOnly">
          <el-input type="textarea" :rows="4" v-model="reason" :readOnly="readOnly" />
        </el-form-item>
      </el-form>
    </template>
  </mcb-detail>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'
import { PRESCRIPTION_RECOGNIZED, ruDate } from '@/components/utils.js'

export default {
  mixins: [DetailPage],

  data () {
    return {
      photo: [],
      clinic: '',
      date: '',
      speciality: '',
      drugs: [''],
      diagnoses: [''],
      reason: '',
      ruDate: ruDate,
    }
  },

  beforeMount () {
    this.loadControlData()
  },

  computed: {
    readOnly () {
      return this.mutableValue.status != PRESCRIPTION_RECOGNIZED
    }
  },

  methods: {
    loadControlData () {
      this.$wait.show()
      this.$axios.post('/prescription/' + this.mutableValue.id + '/control')
        .then(({data}) => {
          this.clinic = data.clinic || ''
          this.date = data.date || ''
          this.speciality = data.speciality || ''
          this.reason = data.reason || ''
          this.diagnoses = data.diagnoses || ['']
          this.drugs = data.drugs || ['']
          this.photo = data.photo || []
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
        .finally(() => {
          this.$wait.hide()
        })
    },

    failureHandle (val) {
      const url = '/prescription/' + this.mutableValue.id + '/failure'
      this.sendData(url, { reason: val })
    },

    successHandle () {
      const data = {}
      if (data.date && data.diagnoses.length && data.drugs.length) {
        const url = '/prescription/' + this.mutableValue.id + '/control/success'
        this.sendData(url, data)
      }
    },

    sendData (url, data) {
      this.$axios.post(url, data)
        .then(({data}) => {
          this.$set(this.mutableValue, 'status', data.status)
          this.$emit('input', this.mutableValue)
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.control {
  .el-textarea {
    width: 100%;
  }

  .photo {
    flex: auto;
    background-color: @gray1-color;   
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  .clinic {
    border: 1px solid red;
    padding: 0 15px;
    border-radius: 4px;
    min-height: 40px;
  }

  .date {
    border: 1px solid blue;
    padding: 0 15px;
    border-radius: 4px;
    min-height: 40px;
  }

  .speciality {
    border: 1px solid yellow;
    padding: 0 15px;
    border-radius: 4px;
    min-height: 40px;
  }

  .diagnose {
    border: 1px solid green;
    margin-bottom: 10px;
    padding: 0 15px;
    border-radius: 4px;
    min-height: 40px;
  }

  .drug {
    border: 1px solid #8B008B;
    margin-bottom: 10px;
    padding: 0 15px;
    border-radius: 4px;
    min-height: 40px;
  }
}
</style>
